<template>
  <div class="container">
    <!-- 数据显示区域开始  -->

    <div class="map" ref="map" id="map" :style="{ height: tableHeight + 'px' }">
      <div class="input-card">
        <el-button type="primary" @click="startEdit" v-if="idCanEdit">
          开始编辑
        </el-button>
        <el-button type="primary" @click="endEdit" v-else> 保存 </el-button>
      </div>
    </div>
    <!-- 数据显示区域结束  -->
  </div>
</template>
<script>
import {
  getGisRangePoints,
  updateGisRangePoints,
  addGisRangePoints,
} from "../../api/gis";
import gismixin from "../../mixin/gisMixin";
import { uuid } from "vue-uuid";
export default {
  mixins: [gismixin],
  mounted() {
    this.type = this.$route.query.type;
    this.gisRangeName = this.$route.query.gisRangeName;
    this.resetSize();
  },
  data() {
    return {
      type: "", //区域类型
      idCanEdit: true,
      gisRangeName: "", //
      // polygon:{},//多边形区域对象
      map: {}, //地图对象
      polyEditor: {}, //多边形编辑对象
    };
  },
  beforeDestroy() {
    // 销毁地图，并清空地图容器
    this.map.destroy();
  },
  methods: {
    resetSize() {
      let h =
        document.documentElement.clientHeight || document.body.clientHeight;
      this.tableHeight = h - 150;
    },
    //开始编辑多边形
    startEdit() {
      this.idCanEdit = false;
      this.polyEditor.open();
    },
    //结束编辑多边形
    endEdit() {
      this.idCanEdit = true;
      this.polyEditor.close();

      if (this.gisRangeName) {
        this.savePath();
      } else if (this.gisRangeName != "") {
        this.addPath();
      }
    },

    //新增围栏路径
    addPath() {
      let polygonPaths = this.polygon.getPath();
      console.log(this.polygon.getPath());
      let savePathList = [];
      for (var i = 0; i < polygonPaths.length; i++) {
        let item = polygonPaths[i];
        savePathList.push({ lat: item.lat, lng: item.lng });
      }
      let pathStr = JSON.stringify(savePathList);
      this.gisRangeName = uuid.v4().replace(/\-/g, "");

      let data = {
        gisRangeName: this.gisRangeName,
        type: this.type,
        gisRangePoint: pathStr,
      };
      addGisRangePoints(data)
        .then((res) => {
          if (res.code == 1) {
            this.$message.success("新增成功");
          } else {
            this.$message.error(res.code);
          }
        })
        .catch((e) => {});
    },
    //修改保存围栏路径
    savePath() {
      let polygonPaths = this.polygon.getPath();
      let savePathList = [];
      for (var i = 0; i < polygonPaths.length; i++) {
        let item = polygonPaths[i];
        savePathList.push({ lat: item.lat, lng: item.lng });
      }
      let pathStr = JSON.stringify(savePathList);
      let data = {
        gisRangeName: this.gisRangeName,
        type: this.type,
        gisRangePoint: pathStr,
      };
      updateGisRangePoints(data)
        .then((res) => {
          if (res.code == 1) {
            this.$message.success("修改成功");
          } else {
            this.$message.error(res.code);
          }
        })
        .catch((e) => {});
    },
    initMap() {
      const container = this.$refs.map;
      var map = new AMap.Map(container, {
        zoom: 12, //设置地图显示的缩放级别
        center: this.mapCenter, //设置地图中心点坐标
        //layers: [new AMap.TileLayer.Satellite(), new AMap.TileLayer.RoadNet()], //设置图层,可设置成包含一个或多个图层的数组
        //mapStyle: "amap://styles/whitesmoke", //设置地图的显示样式
        viewMode: "2D", //设置地图模式
        lang: "zh_cn", //设置地图语言类型
        resizeEnable: true,
      });
      // 同时引入工具条插件，比例尺插件和鹰眼插件
      AMap.plugin(
        [
          "AMap.ToolBar",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.MapType",
          "AMap.Geolocation",
        ],
        function () {
          // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
          map.addControl(new AMap.ToolBar());

          // 在图面添加比例尺控件，展示地图在当前层级和纬度下的比例尺
          map.addControl(new AMap.Scale());

          // 在图面添加鹰眼控件，在地图右下角显示地图的缩略图
          map.addControl(new AMap.OverView({ isOpen: true }));

          // 在图面添加类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
          map.addControl(new AMap.MapType());

          // 在图面添加定位控件，用来获取和展示用户主机所在的经纬度位置
          map.addControl(new AMap.Geolocation());
        }
      );
      this.map = map;
      let that = this;
      this.map.on("complete", function () {
        that.matStatusIsOk = true;
        //重新查询数据
        if (that.gisRangeName) {
          that.getGisRangePointsData();
        } else {
          that.initPolygon();
        }
      });
    },
    //获取设置的围栏点信息
    getGisRangePointsData() {
      let data = { gisRangeName: this.gisRangeName, type: this.type };
      getGisRangePoints(data)
        .then((res) => {
          if (res.code == 1) {
            let item = res.data.list[0];
            let pointStr = item.gisRangePoint;
            let pointArray = JSON.parse(pointStr);
            let pathArray = [];
            for (var i = 0; i < pointArray.length; i++) {
              let pointArrayItem = pointArray[i];
              pathArray.push([pointArrayItem.lng, pointArrayItem.lat]);
            }
            if (pathArray.length > 3) {
              this.path = pathArray;
            }
            this.initPolygon();
          } else {
            console.log(res.code);
          }
        })
        .catch((e) => {});
    },

    initPolygon() {
      var polygon = new AMap.Polygon({
        path: this.path, //设置多边形边界路径
        strokeColor: "#FF33FF", //线颜色
        strokeWeight: 6, //线宽
        strokeOpacity: 0.2, //线透明度
        fillOpacity: 0.4, //填充透明度
        fillColor: "#1791fc", //填充色
        zIndex: 50,
      });
      this.polygon = polygon;
      this.map.add(polygon);
      this.polyEditor = new AMap.PolyEditor(this.map, polygon);
    },
  },
};
</script>

<style lang="less" scoped>
.input-card {
  position: absolute;
  right: 0;
  bottom: 150px;
  z-index: 100000;
}
.map {
  width: 100%;
  height: 300px;
}
</style>